<template>
    <div v-if="getUserDetails" class="payout-details">
        <div class="payout-details-explain">
            <p>Le paiement s'effectue en virement instantanée par défaut</p>
            <ul>
                <li>Montant disponible au retrait : <span>{{ amount }} EUR</span></li>
                <li v-if="getUserDetails">Compte bancaire terminant par : <span>{{ returnBankingAccount }}</span></li>
            </ul>
        </div>

        <button @click="emitPayout()" :disabled="!getUserDetails || !amount" class="generic-btn">{{ $t('wallet.payout_confirm') }}</button>
    </div>
  </template>
  
<script>
import { mapActions, mapGetters } from 'vuex'
  
export default {
    props: {
        amount: {
            type: String,
            required: true
        }
    },
    emits: ['confirmPayout'],
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        getUserDetails: 'getUserDetails',
      }),
      returnBankingAccount() {
        if (this.getUserDetails.banking.iban) {
            return this.getUserDetails.banking.iban.slice(this.getUserDetails.banking.iban.length - 4, this.getUserDetails.banking.iban.length);
        }

        return 'N/A';
      }
    },
    methods: {
        ...mapActions({
            getClient: 'getAccountDetails',
        }),
        emitPayout() {
            this.$emit('confirmPayout');
        }
    },
    mounted() {
        this.getClient();
    }
}
</script>
  
<style scoped lang="scss">
@import '@/assets/styles/root.scss';
  
.payout-details {
    text-align: center;

    .payout-details-explain {

        p {
            margin-bottom: 10px;
        }

        ul {

            li {
                color: $subtitle;

                span {
                    color: white;
                    font-weight: 600;
                }
            }
        }
    }

    button {
        margin: auto;
        margin-top: 20px;
        width: 250px;
        font-size: 15px;
    }
}
</style>
  