<template>
  <Transition name="overlay">
    <div v-if="displayOverlay" class="modal-overlay">
      <Transition name="modal-scale">
        <div v-if="displayModal" class="modal" :style="{ width: modalWidth, height: modalHeight }">
          <div
            class="modal-header"
            :style="{
              background:
                'linear-gradient(to bottom, ' +
                headerColor +
                ' 35%, rgba(38, 103, 255, 0))',
            }"
          >
            <h3>{{ modalTitle }}</h3>
            <img
              :src="require('@/assets/svg/v2/closeCircle.svg')"
              alt="Close icon"
              class="close-icon"
              @click="close"
            />
          </div>
          <div class="modal-content">
            <slot></slot>
          </div>
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'commonModal',
  props: {
    size: {
      type: String,
      validator(value) {
        return ['small', 'medium', 'large'].includes(value)
      },
      required: true,
    },
    height: {
      type: String,
      validator(value) {
        return ['content'].includes(value)
      },
      required: false,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    headerColor: {
      type: String,
      default: 'rgba(444444)',
    },
  },
  emits: ['close'],
  data() {
    return {
      displayOverlay: false,
      displayModal: false,
    }
  },
  computed: {
    modalWidth() {
      switch (this.size) {
        case 'x-small':
          return '500px'
        case 'small':
          return '600px'
        case 'medium':
          return '850px'
        case 'large':
          return '80%'
        case 'x-large':
          return '95%'
        default:
          return '80%'
      }
    },
    modalHeight() {
      switch (this.height) {
        case 'content':
          return 'fit-content'
        default:
          return 'none'
      }
    }
  },
  methods: {
    close() {
      this.displayModal = false

      setTimeout(() => {
        this.displayOverlay = false
      }, 100)

      setTimeout(() => {
        this.$emit('close')
      }, 200)
    },
  },
  mounted() {
    setTimeout(() => {
      this.displayOverlay = true
    }, 100)
    setTimeout(() => {
      this.displayModal = true
    }, 200)
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';
$border-color: rgba(255, 255, 255, 0.3);

.modal-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  backdrop-filter: blur(5px);
}

.modal {
  max-height: 90vh;
  overflow: auto;
  background-color: $common-modal-bg-color;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  border: 1px solid $border-color;
}

.modal-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  color: white;
  border-bottom: 1px solid $border-color;
}

.modal-content {
  padding: 10px 15px;
}

.close-icon {
  max-height: 38px;
  cursor: pointer;
}

.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.5s ease;
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}

.modal-scale-enter-active,
.modal-scale-leave-active {
  transition: transform 0.3s ease;
}

.modal-scale-enter-to,
.modal-scale-leave-from {
  transform: scale(1);
}

.modal::-webkit-scrollbar {
  width: 0px;
}

.modal::-webkit-scrollbar-track {
  background: #333333;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #818181;
  border-radius: 10px;
}

.modal::-webkit-scrollbar-thumb:hover {
  background-color: $base-color;
}

.modal-scale-leave-to,
.modal-scale-enter-from {
  transform: scale(0);
}


@media (max-width: 480px) {
  .modal {
    width: 95%;
    max-width: 95%;
    .modal-header {
      padding: 0 1rem;
      height: 60px;
    }
  }
  .close-icon {
    max-height: 30px;
  }
}
</style>
